<template>
  <el-container>
    <el-header>
      <div style="position:relative">
        <img style="width:30px;height:30px;position: absolute;top:15px;" src="../assets/logo.png" alt="">
        <span style="font-size:20px">北京彼阁数据科技有限公司</span>
        <span class="displaynone" style="font-size:16px">文化和旅游部人才中心全国艺术网络考级考务平台官方合作伙伴</span>
      </div>
      <div>
        <a href="https://yql.bigeshuju.com/" @mouseenter="ahover = 'selectLogin'" @mouseleave="ahover = 'login'"  target="_blank"><img style="vertical-align: middle;margin-right: 10px;" :src="loginUrl" alt="">登录</a>
      </div>
    </el-header>
    <el-main>
      <img class="logoimg" src="../assets/main.png" alt="">
      <div class="maincon">
        <div class="onecon">
          <div class="onebox">
            <img src="../assets/onecon1.png" alt="">
            <p class="onecontitle">考生数量</p>
            <div class="oneconinfo">
              <p>考生数量30万+人</p>
            </div>
          </div>
          <div class="onebox">
            <img src="../assets/onecon2.png" alt="">
            <p class="onecontitle">承办单位</p>
            <div class="oneconinfo">
              <p>各省承办单位400+家</p>
            </div>
          </div>
          <div class="onebox">
            <img src="../assets/onecon3.png" alt="">
            <p class="onecontitle">考点</p>
            <div class="oneconinfo">
              <p>考点3800+家</p>
            </div>
          </div>
          <div class="onebox">
            <img src="../assets/onecon4.png" alt="">
            <p class="onecontitle">考官</p>
            <div class="oneconinfo">
              <p>考官2000+人</p>
            </div>
          </div>
        </div>
        <div class="twocon">
          <div class="title">
            <img src="../assets/left.png" alt="">
            <span>社会艺术考级管理平台</span>
            <img src="../assets/right.png" alt="">
          </div>
          <div class="twobox">
            <div class="boxFirst">
              <img :src="twoUrl" alt="">
              <div class="twoconinfo">
                <div class="twoconinfotitle">{{ title[twoflag] }}</div>
                <p class="twoconinfotext" v-for="(item,index) in textArr[twoflag]" :key="index">
                  <img src="../assets/selectIcon.png" alt="">
                  {{ item }}
                </p>
              </div>
            </div>
            <div class="lineStyle"></div>
            <div class="boxSecond">
              <div  @mouseover="twoflag = 0">
                <img src="../assets/twocon1.png" alt="">
                <div :class="twoflag == 0 ? 'activeText' : 'normalText'">
                  <span>01</span><br/>
                  <span>考前</span>
                </div>
              </div>
              <div @mouseover="twoflag = 1">
                <img src="../assets/twocon2.png" alt="">
                <div :class="twoflag == 1 ? 'activeText' : 'normalText'">
                  <span>02</span><br/>
                  <span>考中</span>
                </div>
              </div>
              <div @mouseover="twoflag = 2">
                <img src="../assets/twocon3.png" alt="">
                <div :class="twoflag == 2 ? 'activeText' : 'normalText'">
                  <span>03</span><br/>
                  <span>考后</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="threecon">
          <div class="title">
            <img src="../assets/left.png" alt="">
            <span>社会艺术考级平台</span>
            <img src="../assets/right.png" alt="">
          </div>
          <div class="threebox">
            <div class="boxFirst">
              <img src="../assets/threecon1.png" alt="">
              <div class="twoconinfo">
                <div class="twoconinfotitle">全类目艺术考级平台</div>
                <p class="twoconinfotext" v-for="(item,index) in threeConArr" :key="index">
                  <img src="../assets/selectIcon.png" alt="">
                  {{ item }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="fourcon">
          <div class="title">
            <img src="../assets/left.png" alt="">
            <span>社会艺术考级平台管理端</span>
            <img src="../assets/right.png" alt="">
          </div>
        </div>
        <div class="fivecon">
          <div class="fiveimgcon">
            <img src="../assets/fivecon1.png" alt="">
            <div class="fivetextcon">线下视频录制</div>
          </div>
          <div class="fiveimgcon">
            <img src="../assets/fivecon2.png" alt="">
            <div class="fivetextcon">考官评审</div>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer>
      <p>Copyright @ 2023 北京彼阁数据科技有限公司 All Rights Reserved <img style="width: 16px;vertical-align: middle;margin-left: 3px;" src="../assets/sign.jpg" alt="">京ICP备16016125号-1</p>
      <p>公司地址：北京市海淀区上地东里一区4号楼4层403</p>
      <p>邮箱：bige_data@163.com  <span class="separate">|</span>
        <a href="https://yql.bigeshuju.com/doc/privacy_policy_kwstu.html" target="_blank">用户协议</a>
        <span class="separate">|</span>
        <a href="https://yql.bigeshuju.com/doc/privacy_policy_kwtea.html" target="_blank">隐私政策</a>
      </p>
    </el-footer>
  </el-container>
</template>

<script>

export default {
  data () {
    return {
      ahover : 'login',
      textArr : [
        ['考前备案','考试申请','考试报名','备考'],
        ['线上考试','线下考试'],
        ['评审','审核','成绩查询','证书']
      ],
      title : ['考前','考中','考后'],
      twoflag : 0,
      threeConArr : ["支持艺术类全类目的考级报名","包含美术、音乐、舞蹈、表演、朗诵等专业的考级考试","线上考试，考生在家即可完成考级流程","海量考级资料，充分备考","为考生提供报名、考试、成绩查询、证书查询全流程服务"]
    }
  },
  computed : {
    twoUrl () {
      return require("@/assets/twoconbig" + (this.twoflag + 1) +".png")
    },
    loginUrl () {
      return require("@/assets/" + this.ahover +".png")
    }
  },

}
</script>

<style scoped>
.separate{
  margin: 0 10px;
}
.el-footer a{
  color: #2a2a2a;
  text-decoration: none;
}
.el-footer a:hover{
  color: #409eff;
}
.el-footer{
  width: 100%;
  padding: 22px 0px;
  box-sizing: border-box;
  margin-top: 120px;
  line-height: 30px;
  background-color: #ececec;
  color: #2a2a2a;
  height: auto!important;
  text-align: center;
  font-size: 12px;
}
a:hover{
  color: #409eff!important;
}
.fivecon{
  display: flex;
  justify-content: space-around;
}

.fivecon>div{
  display: inline-block;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  width: 500px;
  height: 320px;
}
.fivetextcon{
  position: absolute;
  bottom: 0px;
  color: #fff;
  font-size: 22px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
}
.fivecon>div img{
  width: 100%;
  height: 100%;
}
.fourcon{
  background-image: url("../assets/backgroundimg.png");
  width: 100%;
  height: 300px;
  margin: 100px 0px;
  line-height: 300px;
}
.lineStyle{
  width: 800px;
  border-bottom: 2px solid #333;
  margin-top: 80px;
}
.threebox{
  width: 1200px;
  display: block;
  margin: 0 auto;
}
.threebox .boxFirst {
  display: flex;
}
.threebox .boxFirst img{
  width:698px;
  height: 463px;
}
.threebox .twoconinfo{
  width: 350px;
  margin-left: 100px;
  color: #333;
}
.threebox .twoconinfo .twoconinfotext{
  position: relative;
  font-size: 20px;
  padding: 13px 40px;
}
.threebox .twoconinfo .twoconinfotext img{
  width: 28px!important;
  height: 28px!important;
  position: absolute;
  top: 13px;
  left: 0px;
  vertical-align: middle;
  margin-right: 10px;
}
.threebox .twoconinfo .twoconinfotitle{
  border-bottom:1px solid #707070;
  height: 50px;
  line-height: 50px;
  font-size: 26px;
  margin-bottom: 10px;
}
.twobox .twoconinfo{
  width: 250px;
  margin-left: 100px;
  color: #333;
}
.twobox .twoconinfo .twoconinfotext{
  height: 50px;
  line-height: 50px;
  font-size: 20px;
}
.twobox .twoconinfo .twoconinfotext img{
  width: 28px!important;
  height: 28px!important;
  vertical-align: middle;
  margin-right: 10px;
}
.twobox .twoconinfo .twoconinfotitle{
  border-bottom:1px solid #707070;
  height: 50px;
  line-height: 50px;
  font-size: 26px;
}
.boxSecond{
  display: flex;
  justify-content: end;
  margin-top: 80px;
}
.boxSecond span{
  font-size: 30px;
}
.boxSecond .activeText{
  color: #333;
}
.boxSecond .normalText{
  color: #999;
}
.boxSecond>div{
  margin-left: 40px;
  text-align: center;
  cursor: pointer;
}
.twobox {
  width: 1200px;
  display: block;
  margin: 0 auto;
}
.twobox .boxFirst {
  display: flex;
}
.twobox .boxFirst img{
  width:600px;
  height: 413px;
}
.twocon .twobox .boxSecond img{
  width:248px;
  height: 171px;
}
.onecon{
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
}
.onecon .onebox{
  width: 20%;
  text-align: center;
  color: #333;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border: 1px solid #ebeef5;
  display: inline-block;
  padding-bottom:25px;
}
.onecon .onebox .onecontitle{
  margin-top: 10px;
  font-size: 20px;
}
.onecon .onebox .oneconinfo{
  margin-top: 5px;
  font-size: 14px;
}
.onecon .onebox img{
  width: 100%;
}
a{
  color: #fff;
  margin-left: 10px;
  text-decoration: none;
}
a:hover{
  color: #409eff;
}
  .el-header{
    height: 60px;
    line-height: 60px;
    color: #fff;
    background-color: #000;
    display: flex;
    justify-content: space-between;
  }
  .el-header span{
    margin-left:50px
  }
  .el-main{
    padding:0px!important;
  }
  .el-main .logoimg{
    width: 100%;
  }
  .el-main .maincon .title{
    margin-top: 100px;
    text-align: center;
    margin-bottom:75px;
  }
  .el-main .maincon .title span{
    display: inline-block;
    padding: 0 40px;
    font-weight: bold;
    color: #333;
    font-size: 28px;
  }
  .activeCara{
    background-image : linear-gradient(to right,#E77032,#F1A16E);
    color: #fff!important;
  }
  @media only screen and (max-width: 1200px){
    .twocon .twobox {
      width: 985px;
    }
    .twobox .boxFirst img {
      width: 400px;
      height: 252px;
    }
    .twocon .twobox .boxSecond img {
      width: 200px;
      height: 134px;
    }
    .threebox .boxFirst img {
      width: 500px;
      height: 400px;
    }
    .fivecon>div {
      width: 400px;
      height: 257px;
    }

  }
  @media only screen and (max-width: 1000px){
    .displaynone{
      display: none;
    }
    .onecon {
        display: block;
        text-align: center;
        margin-top: 50px;
    }
    .onecon .onebox {
      width: 35%;
      margin-right: 25px;
      margin-top: 25px;
    }
    .el-main .maincon .title {
      margin-top: 50px;
      text-align: center;
      margin-bottom: 50px;
    }
    .title img{
      width: 100px;
    }
    .el-main .maincon .title span {
      padding: 0 20px;
      font-size: 22px;
    }
    .twocon .twobox {
      width: auto;
      padding: 0 10px;
    }
    .twobox .boxFirst img {
      width: 300px;
      height: 252px;
    }
    .twobox .twoconinfo {
        width: 250px;
        margin-left: 35px;
        color: #333;
    }
    .lineStyle {
      width: 400px;
      margin-top: 50px;
    }
    .boxSecond{
        margin-top: 50px;
    }
    .boxSecond>div {
        margin-left: 15px;
    }
    .twocon .twobox .boxSecond img {
        width: 150px;
        height: 134px;
    }
  }
</style>
<style>
.el-main::-webkit-scrollbar { width: 0 !important }
.el-main{ -ms-overflow-style: none; }
.el-main{ overflow: -moz-scrollbars-none; }
</style>
